import {ContainerBuilderInt, ContainerInt} from "@src/lib/di/container/types.ts";
import {Container} from "@src/lib/di/container/di.container.ts";

const get_or_create = () => {
    if (!window.__DI_CONTAINER_335D700_V1__) {
        window.__DI_CONTAINER_335D700_V1__ = new Container()
    }
    return window.__DI_CONTAINER_335D700_V1__
}


const container = (): ContainerInt => {
    return get_or_create()
}

const container_builder = (): ContainerBuilderInt => {
    return get_or_create()
}

export {
    container,
    container_builder
}
