import {
    DiDefinitionType,
    DiInjectorInt
} from "@src/lib/di/injector/types.ts";
import {DiDefinitionFactory} from "@src/lib/di/definition/di.definition.factory.ts";
import {DiDefinitionInt, DiInjectionBasePropsInt} from "@src/lib/di/definition/types.ts";

export class DiInjector implements DiInjectorInt {

    constructor(
        private readonly definitions: Map<string, DiDefinitionInt> = new Map(),
        private readonly tagged: Map<string, DiDefinitionInt[]> = new Map()
    ) {
    }

    add(type: DiDefinitionType, props: DiInjectionBasePropsInt, ctor: any) {
        const definition = DiDefinitionFactory.create(type, props, ctor)
        switch (type) {
            case "tagged":
                const tag = definition.props().tag
                if (!this.tagged.has(tag)) {
                    this.tagged.set(tag, [])
                }
                this.tagged.get(tag)?.push(definition)
                break;
            default:
                this.definitions.set(props.alias, definition)
        }
    }

    has(alias: string): boolean {
        return this.definitions.has(alias)
    }

    get(alias: string): DiDefinitionInt {
        return this.definitions.get(alias) as any
    }

    getByType(type: DiDefinitionType): DiDefinitionInt[] {
        return Array.from(this.definitions)
            .map(([alias]) => {
                return this.get(alias).type() === type ? this.get(alias) : null
            }).filter((one) => one !== null) as any
    }

    getByTag(tag: string): DiDefinitionInt[] {
        return this.tagged.get(tag) ?? []
    }

    all(): Map<string, DiDefinitionInt> {
        return this.definitions
    }

    rem(alias: string) {
        this.definitions.delete(alias)
    }

    toArray(): DiDefinitionInt[] {
        return Array.from(this.definitions)
            .map(([alias]) => {
                return this.get(alias)
            })
    }

}