const hasApiIdentifier = (instance: any, list: string[]): boolean => {
    try {
        if (!instance.__API_IDENTIFIER__) {
            return false
        }
        return list.includes(instance.__API_IDENTIFIER__)
    } catch (e) {
        return false
    }
}

export {
    hasApiIdentifier
}