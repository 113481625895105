import {DiDefinitionInt, DiInjectionBasePropsInt} from "@src/lib/di/definition/types.ts";
import {DiDefinition} from "@src/lib/di/definition/di.definition.ts";
import {DiDefinitionType} from "@src/lib/di/injector/types.ts";

export class DiDefinitionFactory {

    public static create(
        type: DiDefinitionType,
        props: DiInjectionBasePropsInt,
        ctor: any
    ): DiDefinitionInt {
        return new DiDefinition(type, props, ctor)
    }

}