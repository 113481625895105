import {DiDefinitionType} from "@src/lib/di/injector/types.ts";
import {DiDefinitionInt, DiInjectionBasePropsInt} from "@src/lib/di/definition/types.ts";

export class DiDefinition implements DiDefinitionInt {

    constructor(
        private readonly _type: DiDefinitionType,
        private readonly _props: DiInjectionBasePropsInt,
        private readonly _ctor: any
    ) {
    }

    ctor(): any {
        return this._ctor
    }

    props(): DiInjectionBasePropsInt {
        return this._props
    }

    type(): DiDefinitionType {
        return this._type
    }


}