import {DiImporterInt, DiImportModuleInt} from "@src/lib/di/importer/types.ts";

export class DiImporter implements DiImporterInt {

    execute(): Promise<any> {
        return new Promise((resolve) => {
            const module = import.meta.importGlob([
                '../../../module/**/*.di.ts',
                '../../../design/**/*.di.ts',
                '../../../../code/**/*.di.ts',
            ])
            const keys = Object.keys(module)
            this.importNext(keys, module, 0, resolve)
        })
    }

    private importNext(
        keys: string[],
        module: Record<string, DiImportModuleInt>,
        index: number,
        resolve: Function
    ) {
        const key = keys[index]
        if (!key) {
            return resolve()
        }
        module[key]().then(() => {
            index++
            this.importNext(keys, module, index, resolve)
        })
    }

}