import {UuidInt} from "@module/helper/classes/uuid/types.ts";

let __check__: Record<string, boolean> = {}

export class Uuid implements UuidInt {

    short(): any {
        while (true) {
            let uid = ("00000000" + ((Math.random() * Math.pow(36, 8)) | 0).toString(36)).slice(-8);
            if (!__check__.hasOwnProperty(uid)) {
                __check__[uid] = true;
                return uid;
            }
        }
    }

    long(): any {
        let idstr = String.fromCharCode(Math.floor((Math.random() * 25) + 65));
        do {
            let ascicode = Math.floor((Math.random() * 42) + 48);
            if (ascicode < 58 || ascicode > 64) {
                idstr += String.fromCharCode(ascicode);
            }
        } while (idstr.length < 32);
        return (idstr).toLowerCase();
    }

}