import {BootstrapInt, ImportStyleFn} from "@src/lib/boot/types.ts";
import {ContainerInt} from "@src/lib/di/container/types.ts";
import {ViewAdapterInt} from "@module/view/classes/adapter/types.ts";
import {DataRecord, DataRecords} from "@src/types.ts";

export class Bootstrap implements BootstrapInt {

    boot(container: ContainerInt) {
        const app = document.getElementById('app')
        if (!app) {
            return
        }

        //@ts-ignore
        const module = import.meta.importGlob([
            '../../module/**/style.scss',
            '../../../code/**/style.scss',
        ])

        const keys: DataRecords<string> = Object.keys(module)
        this.importNextStyle(0, keys, module)
        const view: ViewAdapterInt = container.get('view-adapter')
        view.create().then(() => {
            view.render(app)
        })
    }

    importNextStyle(
        index: number,
        keys: DataRecords<string>,
        module: DataRecord<ImportStyleFn>
    ) {
        const key = keys[index] ?? null
        if (!key) {
            return
        }
        const lazy = module[key]
        lazy().then(() => {
            index++
            this.importNextStyle(index, keys, module)
        })
    }

}