import {
    ContainerBuilderInt,
    ContainerInt,
    DiGetterPropsInt, DiHandlerPropsInt
} from "@src/lib/di/container/types.ts";
import {DiImporterInt} from "@src/lib/di/importer/types.ts";
import {DiImporter} from "@src/lib/di/importer/di.importer.ts";
import {
    DiInjectionAlias,
    DiInjectionPropsInt,
    DiInjectionServicePropsInt,
    DiInjectionTaggedInt,
    DiInjectorInt
} from "@src/lib/di/injector/types.ts";
import {DiInjector} from "@src/lib/di/injector/di.injector.ts";
import {DiDefinitionInt, DiInjectionBasePropsInt} from "@src/lib/di/definition/types.ts";
import {DiServiceInt} from "@src/lib/di/service/types.ts";
import {hasApiIdentifier} from "@src/lib/api";
import {UuidInt} from "@module/helper/classes/uuid/types.ts";
import {Uuid} from "@module/helper/classes/uuid/uuid.ts";

export class Container implements ContainerInt, ContainerBuilderInt {

    private readonly _importer: DiImporterInt
    private readonly _injector: DiInjectorInt
    private readonly _services: Map<string, any> = new Map()
    private readonly _uuid: UuidInt

    constructor() {
        this._importer = new DiImporter()
        this._injector = new DiInjector()
        this._uuid = new Uuid()
    }

    handle(props: DiHandlerPropsInt) {
        try {
            const key = props.serviceKey
            const service = this.get(key, {
                dependencies: props.deps ?? []
            })
            if (typeof service[props.methode] !== "function") {
                return
            }
            const handler: Function = service[props.methode]
            handler.apply(service, props.args ?? [])
        } catch (e) {
            console.error(e)
        }
    }

    get(alias: DiInjectionAlias, props: DiGetterPropsInt = {}): any {
        if (this._services.has(alias)) {
            return this._services.get(alias)
        }
        return this.create(alias, props)
    }

    getTyped<T>(alias: DiInjectionAlias, props?: DiGetterPropsInt): T {
        return this.get(alias, props)
    }

    tagged(tag: string): any[] {
        const tagged: any[] = []
        const definitions = this._injector.getByTag(tag)
        definitions.forEach((definition) => {
            const ctor = definition.ctor()
            const argumentList = this.argumentList(definition)
            tagged.push(Reflect.construct(ctor, argumentList))
        })
        return tagged
    }

    build(): Promise<ContainerInt> {
        return new Promise((resolve) => {
            this._importer.execute().then(() => {
                const services = this._injector.getByType("service")
                    .sort((a, b) => {
                        const left = a.props().sortOrder ?? 0
                        const right = b.props().sortOrder ?? 0
                        return left - right
                    })
                this.nextService(services, 0, () => {
                    this.afterBuild(resolve)
                })
            })

        })
    }

    inject(props: DiInjectionPropsInt, ctor: any) {
        if (props.alias === "auto-id") {
            props.alias = this._uuid.short()
        }
        this._injector.add("non-service", props as DiInjectionBasePropsInt, ctor)
    }

    injectService(props: DiInjectionServicePropsInt, ctor: any) {
        if (props.alias === "auto-id") {
            props.alias = this._uuid.short()
        }
        this._injector.add("service", props as DiInjectionBasePropsInt, ctor)
    }

    injectTagged(props: DiInjectionTaggedInt, ctor: any) {
        this._injector.add("tagged", props as DiInjectionBasePropsInt, ctor)
    }

    private nextService(many: DiDefinitionInt[], index: number, finish: Function) {
        const definition = many[index] ?? null as any
        if (!definition) {
            return finish()
        }
        const service = this.createService(definition) as DiServiceInt
        const next = () => {
            index++
            this.nextService(many, index, finish)
        }
        if (service.afterCompile) {
            service._set_container(this)
            service.afterCompile().then(() => next())
        } else {
            next()
        }
    }

    private create(alias: string, props: DiGetterPropsInt = {}): any {
        const definition = this._injector.get(alias)
        const ctor = definition.ctor()
        const argumentList = this.argumentList(definition, props.dependencies ?? [])
        const instance = Reflect.construct(ctor, argumentList) as DiServiceInt
        if (hasApiIdentifier(instance, ['di-service'])) {
            instance._set_container(this)
        }
        return instance
    }

    private createService(definition: DiDefinitionInt): any {
        if (this._services.has(definition.props().alias)) {
            return this._services.get(definition.props().alias)
        }
        const ctor = definition.ctor()
        const argumentList = this.argumentList(definition)
        const instance = Reflect.construct(ctor, argumentList)
        this._services.set(definition.props().alias, instance)
        return instance
    }

    private argumentList(definition: DiDefinitionInt, extend: string[] = []) {
        if (!definition.props().dependencies) {
            return extend
        }
        const deps = definition.props().dependencies ?? []
        const list: any[] = this.dependencies(deps)
        return [...list, ...extend]
    }

    dependencies(deps: string[]): any[] {
        const list: any[] = []
        deps.forEach((depKey) => {
            const depDef = this._injector.get(depKey)
            if (depDef) {
                switch (depDef.type()) {
                    case "non-service":
                        list.push(this.get(depKey))
                        break
                    case "service":
                        list.push(this._services.has(depKey) ?
                            this._services.get(depKey) :
                            this.createService(depDef)
                        )
                        break
                }
            }
        })
        return list
    }

    private afterBuild(resolve: Function) {
        const boot: Promise<void>[] = []
        this._services.forEach((_service) => {
            const service: DiServiceInt = _service
            if (hasApiIdentifier(service, ['di-service'])) {
                boot.push(service.boot())
            }
        })
        if (boot.length === 0) {
            return resolve(this)
        }
        Promise.all(boot).then(() => resolve(this))
    }

}