// noinspection JSAnnotator

import "@src/design/index.css"
import "@src/design/scss/all.scss"

import {container_builder} from "@src/lib/di";
import {Bootstrap} from "@src/lib/boot/bootstrap.ts";

const overrideConsole: boolean = true

if (overrideConsole) {
    const ignoreWarnings = [
        "[Vue warn]: Component is missing template or render function.",
        "[intlify] Fall back to translate",
        "[intlify] Not found",
        "[Vue warn]: The `compilerOptions`",
        "[Vue warn]: Component is missing template or render function"
    ]
    const orgWarnFn = console.warn
    console.warn = function () {
        const content = arguments[0] ?? null
        if (!content || typeof content !== "string") {
            return orgWarnFn.apply(console, arguments as any)
        }
        let hide = false
        ignoreWarnings.forEach((ignore) => {
            if (content.includes(ignore)) {
                hide = true
            }
        })
        if (hide) {
            return
        }
        orgWarnFn.apply(console, arguments as any)
    }

}

const builder = container_builder()
const bootstrap = new Bootstrap()

builder.build().then((di) => {
    bootstrap.boot(di)
})